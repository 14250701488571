<template>
    <div></div>
</template>

<script>
import store from '@/store';
 export default {
     created() {
         let projectId = store.getters.getActiveProject.id;
         this.$router.push({ name: 'editProject', params: { projectId:projectId } });
     }
 }

</script>